import '@main/css/app.css'
import '@shared/Css/tailwind.css'

import { createHead } from 'unhead'
import { type DefineComponent } from 'vue'
import { authHandler } from '@modules/Auth'
import { createStore } from '@modules/Store'
import { createApplication, renderPageHandler } from '@modules/App'
import { createRoute, useRoute } from '@modules/Router'
import { createApi, csrfTokenHandler, useApi } from '@modules/Http'

createApplication({
    pages: import.meta.glob<DefineComponent>('./Views/Pages/**/*.vue'),
    setup: (app) => {
        createHead()

        app.use(createApi, {
            request: {},
            response: {
                csrfTokenHandler,
                renderPageHandler,
                authHandler,
            },
        })
        app.use(createRoute)
        app.use(createStore, (store) => {
            store.$api = useApi()
            store.$route = useRoute()
        })

        app.mount('#app')
    },
})
